<template>
    <div>
        <a-row>
            <a-col :span="9">
                <a-form-model layout="inline" :model="form">
                    <a-form-model-item label="">
                        <a-input placeholder="请输入因子名称或化学式" v-model="form.keywords" style="width: 300px"/>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-select style="width: 120px" v-model="form.status">
                            <a-select-option value=''>全部状态</a-select-option>
                            <a-select-option value='1'>可用</a-select-option>
                            <a-select-option value='2'>不可用</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-button type="danger" @click="Clear()">清空</a-button>
                    </a-form-model-item>

                    <a-form-model-item label="">
                        <a-button type="primary" @click="showDrawer({id:0, name:'',chemical:'', status:'1'})">添加检测因子
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-col>
            <a-col :span="7" style="text-align: right">
                <a-space>

                    <a-button type="primary" @click="Factorlist_export">导出数据</a-button>
                </a-space>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="16">
                <table class="layui-table" lay-size="sm">
                    <thead>
                    <tr>
                        <th>因子名称</th>
                        <th>化学式</th>
                        <th>状态</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in Getfactorlist_list">
                        <td>{{item.name}}</td>
                        <td>{{item.chemical}}</td>
                        <td>{{ wordTypeStatus(item.status)}}</td>
                        <td>
                            <a-button type="link" @click="showDrawer(item)">编辑</a-button>
                            <a-button type="link" @click="delClick(item)" class="redcolor">删除</a-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
            </a-col>
        </a-row>

        <div v-if="edit_info">
            <createJianceyinzi :close="onCloseDrawer" :edit_info="edit_info" :edit_time="edit_time" :visible="drawer_visible"></createJianceyinzi>
        </div>

    </div>
</template>

<script>
    import createJianceyinzi from "./create/create";

    export default {
        components: {
            createJianceyinzi,
        },
        data() {
            return {
                edit_info: false,
                edit_time: 0,
                page_show: false,

                page: 1,
                count: 0,
                pagesize: 0,
                Getfactorlist_list: [],
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                form: {
                    status: '',
                    keywords: '',
                },

            }
        },
        watch: {
            form: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },

            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {


            //检测因子导出
            Factorlist_export() {
                console.log(JSON.stringify({
                    page: this.page,
                    keywords: this.form.keywords,
                    status: this.form.status,
                }))
                this.$sa0.down({
                    url: this.$api('Factorlist_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        page: this.page,
                        keywords: this.form.keywords,
                        status: this.form.status,
                    },
                },)
            },


            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.form.keywords = this.$route.query.keywords || '';
                this.form.status = this.$route.query.status || '';
                this.Getfactorlist()
                setTimeout(() => {
                    this.page_show = true
                }, 100)
            },

            //搜索
            searchClick() {
                //console.log(this.form.keywords)
                this.$router.push({
                    query: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        page: 1,
                    }
                })
            },

            Clear() {
                this.form.status = '';
                this.form.keywords = '';
                this.page = 1;
            },

            Getfactorlist() {
                this.$sa0.post({
                    url: this.$api('Getfactorlist'),
                    data: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        page: this.page,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Getfactorlist_list = response.data.return.list;
                            //console.log(this.Getfactorlist_list);
                            this.count = response.data.return.count;//分页
                            this.pagesize = response.data.return.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            delClick(item) {
                //console.log(item)
                this.$sa0.post({
                    url: this.$api('Del_factor'),
                    data: {
                        id: item.id,
                    },

                }).then((response) => {
                    //console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('已删除')
                            this.Getfactorlist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        keywords: this.form.keywords,
                        status: this.form.status,
                    }
                })
            },

            // 类型转换-编号分类
            wordTypeStatus(status) {
                let type_arr = ['可用', '不可用']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },


            handleChange(value) {
                console.log(`selected ${value}`);
            },

            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                this.edit_info = item;
                console.log(this.edit_info);
                this.edit_time = new Date() / 1;
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.Getfactorlist();
                this.drawer_visible = false;
                this.edit_info = false
            },
        }
    }
</script>

<style>


</style>
