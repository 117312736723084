<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${form.id===0?'添加':'编辑'}检测因子`" width="36%" placement="right" :closable="true"
                  :visible="visible" @close="onClose">
            <div>
                <a-row>
                    <a-col :span="12">
                        <a-form-model :model="form">
                            <a-form-model-item label="因子名称" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span: 16, offset: 0 }">
                                <a-input v-model="form.name"/>
                            </a-form-model-item>
                            <a-form-model-item label="化学式" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span: 16, offset: 0 }">
                                <a-input v-model="form.chemical"/>
                            </a-form-model-item>
                            <a-form-model-item label="状态" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span: 16, offset: 0 }">
                                <a-select v-model="form.status">
                                    <a-select-option value='1'>可用</a-select-option>
                                    <a-select-option value='2'>不可用</a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item label="" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span: 16, offset: 8 }">
                                <a-space>
                                    <a-button type="primary" @click="doClick(true)">确定</a-button>
                                    <a-button @click="doClick(false)">保存并继续添加</a-button>
                                </a-space>
                            </a-form-model-item>
                        </a-form-model>
                    </a-col>
                </a-row>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_time', 'edit_info'],
        data() {
            return {
                form: {
                    id: 0,
                    name: '',
                    chemical: '',
                    status: '1'
                },
            }
        },
        watch: {
            edit_time() {
                this.form = JSON.parse(JSON.stringify(this.edit_info))
            },
        },
        mounted() {
            this.form = JSON.parse(JSON.stringify(this.edit_info))
        },
        methods: {
            doClick(c = true) {
                let data = {
                    id: this.form.id,
                    name: this.form.name,
                    chemical: this.form.chemical,
                    status: this.form.status,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.form.id !== 0 ? this.$api('Edit_factor') : this.$api('Create_factor'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            if (this.form.id !== 0) {
                                layer.msg('已保存')
                            } else {
                                layer.msg('已添加')
                            }
                            if (c) {
                                this.close()
                            } else {
                                this.form = {
                                    id: 0,
                                    name: '',
                                    chemical: '',
                                    status: '1'
                                }
                            }

                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },


            handleChange(value) {
                console.log(`selected ${value}`);
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<style scoped>

</style>
